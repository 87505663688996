<template>
  <main>
    <b-row class="m-0">
      <b-col
        lg="7"
        class="pl-0"
      >
        <h2 v-html="$t('meta.title')"></h2>
        <p v-html="$t('meta.desc')"></p>
      </b-col>
      <b-col
        class="alert alert-info"
        lg="5"
        v-html="$t('rules', { n: result })"
      />
    </b-row>
    <b-row class="m-0">
      <div
        v-for="index in Object.keys($t('tiles')).length"
        :key="index"
        :class="`tile text-center ${tiles[index-1] ? 'checked' : ''}`"
      >
        <div class="text-left">
          <input
            :id="`tile_${index}`"
            v-model="tiles[index-1]"
            type="checkbox"
            @change="bingoCount()"
          />
        </div>
        <label :for="`tile_${index}`">
          <p
            class="title"
            v-html="$t(`tiles.t${index}[0]`)"
          ></p>
          <p
            class="desc"
            v-html="$t(`tiles.t${index}[1]`)"
          ></p>
        </label>
      </div>
    </b-row>
    <h2
      class="mt-4"
      v-html="$t('share')"
    ></h2>
    <input
      v-model="share"
      class="form-control"
      value
      readonly
      type="text"
    />
    <div class="hidden">
      <!-- <audio
        v-for="n in 9"
        :key="n"
        :ref="`fart0${n}`"
        :src="`${$t('/')}media/fart0${n}.wav`"
      ></audio> -->
      <audio
        ref="fart01"
        :src="`${$t('/')}media/fart01.wav`"
      ></audio>
      <audio
        ref="fart02"
        :src="`${$t('/')}media/fart02.wav`"
      ></audio>
      <audio
        ref="fart03"
        :src="`${$t('/')}media/fart03.wav`"
      ></audio>
      <audio
        ref="fart04"
        :src="`${$t('/')}media/fart04.wav`"
      ></audio>
      <audio
        ref="fart05"
        :src="`${$t('/')}media/fart05.wav`"
      ></audio>
      <audio
        ref="fart06"
        :src="`${$t('/')}media/fart06.wav`"
      ></audio>
      <audio
        ref="fart07"
        :src="`${$t('/')}media/fart07.wav`"
      ></audio>
      <audio
        ref="fart08"
        :src="`${$t('/')}media/fart08.wav`"
      ></audio>
      <audio
        ref="fart09"
        :src="`${$t('/')}media/fart09.wav`"
      ></audio>
      <audio
        ref="tadaa"
        :src="`${$t('/')}media/tadaa.wav`"
      ></audio>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      numbers: [],
      share: this.$t('url'),
      count: 0,
      result: '<b class="text-warning">0</b>',
      tiles: [...Array(Object.keys(this.$t('tiles')).length).keys()].map(i => !(i + 1)),
      bingos: [['1', '2', '3', '4', '5'],
        ['6', '7', '8', '9', '10'],
        ['11', '12', '13', '14', '15'],
        ['16', '17', '18', '19', '20'],
        ['21', '22', '23', '24', '25'],
        ['1', '6', '11', '16', '21'],
        ['2', '7', '12', '17', '22'],
        ['3', '8', '13', '18', '23'],
        ['4', '9', '14', '19', '24'],
        ['5', '10', '15', '20', '25'],
        ['1', '7', '13', '19', '25'],
        ['5', '9', '13', '17', '21']],
    };
  },
  mounted() {
    if (this.$t('hash')) {
      this.numbers = this.$t('hash').replace('#', '').split('-');
      this.numbers.forEach((n) => {
        this.tiles[n - 1] = true;
      });
      this.bingoCount();
    }
  },
  methods: {
    bingoCount() {
      /* Numbers list update */
      this.numbers = [];
      this.tiles.forEach((n, i) => {
        if (n) {
          this.numbers.push(`${i + 1}`);
        }
      });

      /* Results */
      const currentCount = this.count;
      this.count = this.numbers.length;
      for (let i = 0; i < this.bingos.length; i += 1) { // line, column, diagonal
        if ((this.numbers.indexOf(this.bingos[i][0]) > -1)
          && (this.numbers.indexOf(this.bingos[i][1]) > -1)
          && (this.numbers.indexOf(this.bingos[i][2]) > -1)
          && (this.numbers.indexOf(this.bingos[i][3]) > -1)
          && (this.numbers.indexOf(this.bingos[i][4]) > -1)) {
          this.count += 5;
        }
      }
      this.result = `<b class="text-warning">${this.count}</b>`;

      /* Sound */
      if (this.count === 85) {
        this.$refs.tadaa.play();
      } else if (currentCount < this.count) {
        const fart = `fart0${Math.floor(Math.random() * Math.floor(9)) + 1}`;
        console.log(fart);
        this.$refs[fart].play();
      }

      /* Share link update */
      this.share = `${this.$t('baseurl')}#${this.numbers.join('-')}`;
      window.location.href = `#${this.numbers.join('-')}`;
    },
  },
};
</script>
