<template>
  <main>
    <section id="manifest">
      <b-row class="mt-5">
        <b-col
          lg="8"
          class="mx-auto alert alert-secondary"
          v-html="$t('manifest')"
        />
      </b-row>
    </section>
    <section id="proutify">
      <b-row class="mt-5">
        <b-col lg="8">
          <div v-html="$t('proutify')"></div>
          <b-row>
            <b-button
              href="https://asso.framasoft.org/dolo/h/proutify-firefox"
              variant="warning col-9 col-md-5 mx-auto my-4"
            >
              <i class="fab fa-firefox-browser fa-2x d-block text-center"></i>
              Ajouter Proutify à son navigateur Firefox
            </b-button>
          </b-row>
          <b-row>
            <b-button
              href="https://asso.framasoft.org/dolo/h/proutify-chrome"
              variant="info col-9 col-md-5 mx-auto my-4"
            >
              <i class="fab fa-chrome fa-2x d-block text-center"></i>
              Ajouter Proutify à son navigateur Chrome / Chromium
            </b-button>
          </b-row>
        </b-col>
        <b-col lg="4">
          <b-img
            alt=""
            fluid
            :src="`${$t('baseurl')}img/proutify.jpg`"
            thumbnail
          />
        </b-col>
      </b-row>
    </section>
    <section id="memes">
      <b-row class="mt-5">
        <b-col v-html="$t('memes')" />
      </b-row>
      <b-row class="mx-0 p-2 rounded bg-light">
        <b-col
          v-for="name in [
            'anar', 'austin-power', 'balladur', 'brice', 'change-my-mind',
            'charlie', 'crying', 'dawson', 'double-dare', 'fire', 'gatsby',
            'great', 'gump', 'jeff-tuche', 'jesus', 'marvel', 'moise',
            'one-does-not-simply', 'oss1', 'oss2', 'pagny', 'startup',
            'toys-story', 'yvain']"
          :key="name"
          class="p-2"
        >
          <a
            class="rounded border-0 d-block"
            :style="`background: url(${$t('baseurl')}img/memes/${name
            }.jpg) no-repeat center center; background-size: cover; width: 150px; height: 150px;`"
            @click="meme = name; $bvModal.show('modal-meme')"
          ></a>
        </b-col>
      </b-row>
      <b-modal
        id="modal-meme"
        :title="`${meme}.jpg`"
        :cancel-title-html="$t('txt.close')"
        cancel-variant="light"
        ok-variant=" d-none"
        button-size="sm"
      >
        <p>
          <img
            class="img-fluid"
            :src="`${$t('baseurl')}img/memes/${meme}.jpg`"
          />
        </p>
        <pre><code v-html="`${$t('baseurl')}img/memes/${meme}.jpg`"></code></pre>
      </b-modal>
    </section>
    <section ref="prout-o-meter">
      <b-row class="mt-5">
        <b-col lg="7">
          <h2 v-html="$t('meta.title')"></h2>
          <p v-html="$t('meta.desc')"></p>
        </b-col>
        <b-col lg="5">
          <div
            class="alert alert-info"
            v-html="$t('rules', { n: result })"
          ></div>
        </b-col>
      </b-row>
      <b-row class="mx-0">
        <div
          v-for="index in Object.keys($t('tiles')).length"
          :key="index"
          :class="`tile text-center ${tiles[index-1] ? 'checked' : ''}`"
        >
          <div class="text-left">
            <input
              :id="`tile_${index}`"
              v-model="tiles[index-1]"
              type="checkbox"
              @change="bingoCount()"
            />
          </div>
          <label :for="`tile_${index}`">
            <p
              class="title"
              v-html="$t(`tiles.t${index}[0]`)"
            ></p>
            <p
              class="desc"
              v-html="$t(`tiles.t${index}[1]`)"
            ></p>
          </label>
        </div>
      </b-row>
      <b-row>
        <b-col>
          <h3
            class="mt-4"
            v-html="$t('share')"
          ></h3>
          <input
            v-model="share"
            class="form-control"
            value
            readonly
            type="text"
          />
          <div class="hidden">
            <audio
              ref="fart01"
              :src="`${$t('/')}media/fart01.wav`"
            ></audio>
            <audio
              ref="fart02"
              :src="`${$t('/')}media/fart02.wav`"
            ></audio>
            <audio
              ref="fart03"
              :src="`${$t('/')}media/fart03.wav`"
            ></audio>
            <audio
              ref="fart04"
              :src="`${$t('/')}media/fart04.wav`"
            ></audio>
            <audio
              ref="fart05"
              :src="`${$t('/')}media/fart05.wav`"
            ></audio>
            <audio
              ref="fart06"
              :src="`${$t('/')}media/fart06.wav`"
            ></audio>
            <audio
              ref="fart07"
              :src="`${$t('/')}media/fart07.wav`"
            ></audio>
            <audio
              ref="fart08"
              :src="`${$t('/')}media/fart08.wav`"
            ></audio>
            <audio
              ref="fart09"
              :src="`${$t('/')}media/fart09.wav`"
            ></audio>
            <audio
              ref="tadaa"
              :src="`${$t('/')}media/tadaa.wav`"
            ></audio>
          </div>
        </b-col>
      </b-row>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      numbers: [],
      share: this.$t('url'),
      count: 0,
      result: '<b class="text-warning">0</b>',
      tiles: [...Array(Object.keys(this.$t('tiles')).length).keys()].map(i => !(i + 1)),
      bingos: [['1', '2', '3', '4', '5'],
        ['6', '7', '8', '9', '10'],
        ['11', '12', '13', '14', '15'],
        ['16', '17', '18', '19', '20'],
        ['21', '22', '23', '24', '25'],
        ['1', '6', '11', '16', '21'],
        ['2', '7', '12', '17', '22'],
        ['3', '8', '13', '18', '23'],
        ['4', '9', '14', '19', '24'],
        ['5', '10', '15', '20', '25'],
        ['1', '7', '13', '19', '25'],
        ['5', '9', '13', '17', '21']],
      meme: '',
    };
  },
  mounted() {
    if (/[0-9]+/.test(this.$t('hash').replace('#', '').replace('-', ''))) {
      this.numbers = this.$t('hash').replace('#', '').split('-');
      this.numbers.forEach((n) => {
        this.tiles[n - 1] = true;
      });
      if (this.numbers.length > 0) {
        this.$refs['prout-o-meter'].scrollIntoView({ behavior: 'smooth' });
      }
      this.bingoCount();
    }
  },
  methods: {
    bingoCount() {
      /* Numbers list update */
      this.numbers = [];
      this.tiles.forEach((n, i) => {
        if (n) {
          this.numbers.push(`${i + 1}`);
        }
      });

      /* Results */
      const currentCount = this.count;
      this.count = this.numbers.length;
      for (let i = 0; i < this.bingos.length; i += 1) { // line, column, diagonal
        if ((this.numbers.indexOf(this.bingos[i][0]) > -1)
          && (this.numbers.indexOf(this.bingos[i][1]) > -1)
          && (this.numbers.indexOf(this.bingos[i][2]) > -1)
          && (this.numbers.indexOf(this.bingos[i][3]) > -1)
          && (this.numbers.indexOf(this.bingos[i][4]) > -1)) {
          this.count += 5;
        }
      }
      this.result = `<b class="text-warning">${this.count}</b>`;

      /* Sound */
      if (this.count === 85) {
        this.$refs.tadaa.play();
      } else if (currentCount < this.count) {
        const fart = `fart0${Math.floor(Math.random() * Math.floor(9)) + 1}`;
        this.$refs[fart].play();
      }

      /* Share link update */
      this.share = `${this.$t('baseurl')}#${this.numbers.join('-')}`;
      window.location.href = `#${this.numbers.join('-')}`;
    },
  },
};
</script>

<style lang="scss">
main {
  padding-bottom: 25px;

  label {
    font-weight:normal;
  }

  .tile {
    width: 216px;
    height: 170px;
    float: left;
    position: relative;
    background-color: var(--f-g1);
    border: 1px solid var(--f-g6);
    padding:5px;

    &:hover {
      background-color: var(--f-b2);
    }

    &.checked {
      background-color: var(--f-g1);
      background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="111.966" height="110" viewBox="0 0 104.968 103.125" fill="%23b4aac3"><path d="M68.7.004c-7.875.082-15.832 5.092-18.63 12.528-.517 3.02 4.124 4.474 5.349 1.622 4.157-8.922 17.266-11.213 24.121-4.103 7.543 6.775 5.972 20.286-2.877 25.21-2.517 2.043.41 6.427 3.235 4.717 3.666-3.21 7.166-6.795 11.909-2.653 9.308 5.261 9.999 20.005 1.247 26.14-5.276 4.201-13.063 3.929-18.435.077-3.065-1.16-5.19 3.635-2.378 5.22 4.924 4.43 12.664 2.869 17.655 3.86 6.298 8.525 1.218 22.306-9.295 24.325-8.55 2.191-17.554-4.466-18.65-13.08-.967-2.997-5.808-1.91-5.473 1.197 1.456 11.962 14.48 20.848 26.125 17.349 11.931-2.88 18.89-17.08 14.373-28.374-2.144-3.455-2.709-5.525 1.351-7.528 9.233-8.418 8.67-24.688-1.157-32.412-2.613-3.664-11.139-2.48-7.544-8.106C92.57 13.853 82.94.51 70.331.044l-.817-.037zm-5.966 26.933C50.58 28.915 38.367 30.75 26.267 32.97c-3.002 1.226-1.407 6.174 1.746 5.416 11.984-2.035 24.046-3.768 35.98-5.992 2.733-1.27 1.764-5.509-1.26-5.457zM1.37 44.595c-2.224.67-1.345 3.696-.063 4.903C3.423 51.17 1.4 52.84.401 54.51c-1.314 1.39.578 4.853 2.14 2.795 1.123-2.175 2.246-3.888 3.367-.717.353 1.57 2.581 1.877 2.88.12.79-2.249-2.138-3.838-2.172-5.68 1.107-1.732 3.69-4.493 1.316-6.3-1.957-.428-2.352 2.473-3.473 3.535-1.059-1.086-1.434-3.395-3.09-3.668zM28.824 45.6c-3.28-.072-3.737 5.182-.493 5.677 8.704.38 17.441.945 26.14 1.046 3.12-.632 2.579-5.687-.605-5.645-8.347-.357-16.694-.733-25.042-1.078zm-4.517 14.339c-3.222-.206-3.962 4.912-.815 5.628 14.423 4.324 28.81 8.796 43.256 13.027 3.315.497 4.356-4.812 1.1-5.605-14.422-4.329-28.813-8.787-43.254-13.034l-.286-.016z" /></svg>');
      background-position: center center;
      background-repeat: no-repeat;
    }

    input {
      position: absolute;
      left: 7px;
      top: 7px;
    }
  }

  .title {
    min-height: 45px;
    text-transform: uppercase;
    max-width: 75%;
    margin: 0 auto 10px;
  }

  #manifest h2 {
    text-align: center;
    font-size: 2.5rem;

    &::after {
      content: none;
    }
  }
}

#f-i18n {
  display: none;
}
</style>
